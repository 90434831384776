$link-color: #ff8f00;

table {
  &.prisma-cms {
    border-collapse: collapse;

    td,
    th {
      padding: 3px 5px;
    }

    &.bordered {
      td,
      th {
        border: 1px solid #ddd;
      }
    }

    &.centered {
      td,
      th {
        text-align: center;
      }
    }
  }
}

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

a {
  color: $link-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

header {
  .login {
    color: $link-color;

    a {
      display: flex;
    }
  }
}

table {
  // Карточка объекта
  &.object {
    td,
    th {
      padding: 15px;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
    th {
      text-align: left;
      width: 150px;
    }
  }
}
