html,
body,
#root {
  height: 100%;
}

table.prisma-cms {
  border-collapse: collapse; }
  table.prisma-cms td,
  table.prisma-cms th {
    padding: 3px 5px; }
  table.prisma-cms.bordered td,
  table.prisma-cms.bordered th {
    border: 1px solid #ddd; }
  table.prisma-cms.centered td,
  table.prisma-cms.centered th {
    text-align: center; }

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87); }

a {
  color: #ff8f00;
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

header .login {
  color: #ff8f00; }
  header .login a {
    display: flex; }

table.object td,
table.object th {
  padding: 15px;
  border-bottom: 1px solid #e0e0e0; }

table.object th {
  text-align: left;
  width: 150px; }

